





















































































import { Component, Inject, Vue } from 'vue-property-decorator';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import ControlLabel from '@/themes/v1/components/ControlLabel.vue';
import StarRatingSelect from '@/themes/v1/components/StarRatingSelect.vue';
import YesNoSelector from '@/themes/v1/components/YesNoSelect.vue';
import ControlError from '@/themes/v1/components/ControlError.vue';
import MessageBox from '@/themes/v1/components/MessageBox.vue';
import BoxPage from '@/themes/v1/components/BoxPage.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { FeedbackService } from '@/modules/sts/feedback/feedback.service';
import config from '@/env';
import { AllowFeedbackStatus } from '@/modules/sts/feedback/feedback-status.model';
import { OnMounted, SsrCtx, WithAsyncData } from '@/core/vue.types';
import { FeedbackOptions } from '@/modules/sts/feedback/feedback-options.model';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { pushWithLangAndPlatform } from '@/themes/v1/fns/router';

@Component({
  components: {
    BoxPage,
    MessageBox,
    ControlError,
    YesNoSelector,
    StarRatingSelect,
    ControlLabel,
    BoxContent,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Feedback extends Vue implements OnMounted, WithAsyncData {
  @Inject()
  feedbackService!: FeedbackService;

  form: any = {
    score: null,
    optionalScores: {},
    message: '',
    solved: null,
  };
  options: FeedbackOptions = {
    criteria: [],
  };
  submitTouched = false;

  async asyncData(ctx: SsrCtx) {
    const feedbackService = resolveFromCtx<FeedbackService>(
      'feedbackService',
      ctx
    );
    const options = await feedbackService.getOptions(ctx.app.$i18n.locale);
    const ticketStatus = await feedbackService.getStatus(
      ctx.route.params.feedbackId
    );
    if (!ticketStatus) {
      ctx.redirect(
        ctx.app.$routerHelper.withPlatformAndLang(
          ctx.route,
          `feedback/${AllowFeedbackStatus.Outdated}`
        ),
        302
      );
    }
    if (ticketStatus.status != AllowFeedbackStatus.Allowed) {
      ctx.redirect(
        ctx.app.$routerHelper.withPlatformAndLang(
          ctx.route,
          `feedback/${ticketStatus.status}`
        ),
        302
      );
    }
    const result: any = { options };
    if (ticketStatus.model != null) {
      result.form = ticketStatus.model;
    }
    return result;
  }

  mounted() {
    if (this.$route.query['score']) {
      this.form.score = Number(this.$route.query['score']);
    }
  }

  async submit(validateForm) {
    const valid = await validateForm({ silent: false });
    if (!valid) {
      this.submitTouched = true;
      return;
    }
    const command = {
      ...this.form,
      game: config.ticketsGame,
      ticketId: this.$route.params.feedbackId,
    };
    const success = await this.feedbackService.sendFeedback(command);
    if (success) {
      await pushWithLangAndPlatform(this.$router, `feedback/success`);
    } else {
      await pushWithLangAndPlatform(this.$router, `tickets/failed`);
    }
  }
}
