export interface FeedbackStatus {
  status: AllowFeedbackStatus;
  model: TicketFeedback;
}

export enum AllowFeedbackStatus {
  Allowed = 'allowed',
  Outdated = 'outdated',
  AlreadyRated = 'alreadyRated',
  WasReopened = 'wasReopened',
  Rejected = 'rejected',
}

export interface TicketFeedback {
  score: number;
  message: string;
  solved: boolean | null;
  optionalScores: { [key: string]: number };
}
